import * as React from "react"
import { Link } from "gatsby"
import { Waypoint } from 'react-waypoint'

import Layout from "../components/layout"
import Seo from "../components/seo"

import QuoteDesktop from "../images/quote-support-resources-desktop.png"
import QuoteMobile from "../images/quote-support-resources-mobile.png"
import Gutter from "../images/gutter-support-and-resources.png"

import IconNext from "../images/icon-next.png"
import IconDownload from "../images/icon-download.svg"

import LockUpStethoscope from "../images/lockup-stethoscope-support-resources.svg"
import LockUpHug from "../images/lockup-hug-support-resources.svg"
import LockUpHeart from "../images/lockup-heart-support-resources.svg"

import HexCaringForLovedOne from "../images/hex-caring-for-loved-one.png"
import HeroImage from "../images/hero-support-and-resources.png"

import ThumbPmrBrochure from "../images/thumb-kevzara-pmr-brochure.png"

import BrochurePdf from "../pdf/kevzara-pmr-and-you-brochure.pdf"

const SupportAndResources = () => {

  const handleVerticalLineAnimations = () => {
    const target = document.querySelectorAll('.vertical-line-dark-red');
    target.forEach((element) => {
      element.classList.add('animate');
    });
  }

  return (
    <Layout>
      <Seo
        title="Support and Resources"
        desc="Build a support system with helpful resources."
        ogtitle="Support and Resources | PMR and You Website"
        ogdesc="Build a support system with helpful resources."
      />

      <div id="support-resources-hero-margin">
        <div className="main-hero support-and-resources">
          <h1 className="headline-hero">Building Your Support System</h1>
        </div>

        <div id="support-resources-hero-image" className="text-right"><img src={HeroImage} alt="woman holding gardening tools" id="support-resources-hero-image-img" /></div>
      </div>

      <div id="support-resources-content-margin">
        <div className="content-container mb-4 md_mb-4 lg_mb-2">
          <h2 className="headline-body mb-1 md_mb-1">Many people have not heard of PMR</h2>
          <ol id="support-resources-hero-list">
            <li className="one"><strong>Share your diagnosis</strong> to let the people who care about you know how they can help</li>
            <li className="two"><strong>Tell your friends, family, and loved ones</strong> how PMR is affecting you</li>
            <li className="three"><strong>Don’t be afraid to ask for help</strong> with things like getting out of bed or getting a ride to your doctor's office</li>
          </ol>
        </div>

        <div className="content-container mb-0 md_mb-0 lg_mb-2">
          <div className="row">
            <div className="columns small-12 medium-12 large-5 align-self-middle">
              <div className="callout red">
                Having a support team to lift you up when you&apos;re feeling down can make living with PMR feel less challenging.
              </div>
            </div>
            <div className="columns small-12 medium-12 large-1 align-self-middle text-right">&nbsp;</div>
            <div className="columns small-12 medium-12 large-6 align-self-middle text-center large-text-right">
              <div className="mt-2 mb-4 hide-for-large"><img src={QuoteMobile} alt="[PMR feels like] when you spill coffee--it's unexpected and it touches everything. Everything you are doing. --a real person with PMR" /></div>
              <div className="md_mb-2 show-for-large"><img src={QuoteDesktop} alt="[PMR feels like] when you spill coffee--it's unexpected and it touches everything. Everything you are doing. --a real person with PMR" /></div>
            </div>
          </div>
        </div>

        <div className="row" id="talk-to-your-doctor">
          <div className="columns small-12 medium-12 large-7" id="talk-doctor-copy">
            <p className="headline-body">Talk with your doctor</p>
            <p>If you have pain and stiffness in your hips, shoulders, and neck, your primary care doctor may suspect PMR. Ask if you need a referral to see a rheumatologist, who will test you for PMR and prescribe treatment.</p>
            <p>If you've been diagnosed with PMR, your doctor will work with you to find a treatment plan that's right for you. It's important to ask questions to reach your goals.</p>
          </div>

          <div className="columns small-12 medium-12 large-5 text-right mb-2" id="talk-doctor-stethoscope">
            <img src={LockUpStethoscope} alt="stethoscope and shapes icon" />
          </div>
        </div>

        <div className="content-container">
          <div>
            <p className="headline-subhead">Questions for your doctor</p>
            <p>Get the conversation started by asking:</p>
          </div>

          <div className="callout light-gray mt-0 mb-2 md_mt-0 md_mb-4">
            <div className="row lg_mb-2">
              <div className="columns small-12 medium-12 large-3">
                <p><span className="font-weight-normal">What is the treatment for PMR?</span></p>
              </div>
              <div className="columns small-12 medium-12 large-1 align-self-middle">
                <div className="vertical-line-dark-red h74px left show-for-large"></div>
                <div className="horizontal-line-dark-red hide-for-large mt-2 mb-3"></div>
              </div>
              <div className="columns small-12 medium-12 large-4">
                <p><span className="font-weight-normal">How long will it take for my PMR symptoms to go away?</span></p>
              </div>
              <div className="columns small-12 medium-12 large-1 align-self-middle">
                <div className="vertical-line-dark-red h74px left show-for-large"></div>
                <div className="horizontal-line-dark-red hide-for-large mt-2 mb-3"></div>
              </div>
              <div className="columns small-12 medium-12 large-3">
                <p><span className="font-weight-normal">How long will I be on treatment?</span></p>
              </div>
              <div className="columns small-12 medium-12 hide-for-large">
                <div className="horizontal-line-dark-red hide-for-large mt-2 mb-3"></div>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-3">
                <p><span className="font-weight-normal">Are there any side effects of the treatment?</span></p>
              </div>
              <div className="columns small-12 medium-12 large-1 align-self-middle">
                <div className="vertical-line-dark-red h74px left show-for-large"></div>
                <div className="horizontal-line-dark-red hide-for-large mt-2 mb-3"></div>
              </div>
              <div className="columns small-12 medium-12 large-4">
                <p><span className="font-weight-normal">How often should I schedule follow-up visits?</span></p>
              </div>
              <div className="columns small-12 medium-12 large-1 align-self-middle">
                <div className="vertical-line-dark-red h74px left show-for-large"></div>
                <div className="horizontal-line-dark-red hide-for-large mt-2 mb-3"></div>
              </div>
              <div className="columns small-12 medium-12 large-3">
                <p><span className="font-weight-normal">What should I do if my symptoms persist?</span></p>
              </div>
            </div>
          </div>
        </div>

        <Waypoint onEnter={handleVerticalLineAnimations}/>

        <div className="content-container">
          <div className="row lg_mb-2">
            <div className="columns small-12 medium-12 large-3 text-center large-text-left pt-2 pb-2">
              <img src={HexCaringForLovedOne} alt="woman watering plants" />
            </div>
            <div className="columns small-12 medium-12 large-1" style={{ maxWidth: '40px' }}>&nbsp;</div>
            <div className="columns small-12 medium-12 large-8" style={{ minWidth: '70%' }}>
              <p className="headline-body">Caring for a loved one with PMR</p>
              <p>The role of caregiver begins with trust. If your loved one has asked you to be a caregiver, you may need to help them with things like:</p>
              <div className="row">
                <div className="columns small-12 medium-12 large-8">
                  <ul className="dark-red-bullets">
                    <li>Taking them to <strong>doctor visits</strong></li>
                    <li>Keeping them <strong>company</strong></li>
                    <li><strong>Helping</strong> them with daily activities</li>
                    <li><strong>Listening</strong> when they need to talk to someone</li>
                  </ul>
                </div>
                <div className="columns small-12 medium-12 large-4 text-center large-text-right align-self-middle pt-3 pb-3">
                  <img src={LockUpHug} alt="hugging icon" />
                </div>
              </div>
            </div>
          </div>

          <div>
            <p>As a caregiver, it can be hard to watch someone you love struggle with PMR. Know that your help with daily tasks or even just being there to listen makes a difference for them.</p>
            <p>PMR doesn’t just affect patients. Friends, family, and caregivers feel the impact as well.</p>
          </div>

          <div className="callout red mt-4 md_mt-4">
            Your support goes a long way in helping your loved one cope with PMR.
          </div>

          <div className="text-center pt-4 pb-4 md_pt-4 md_pb-4">
            <img src={LockUpHeart} alt="hands holding heart icon”" />
          </div>

          <div className="mb-3 md_mb-3">
            <p className="headline-body">Seek support for living with PMR</p>
            <p>PMR can make you feel like you’re alone. You don’t have to go through this journey on your own. Here are links with more information about PMR.*</p>

            <div className="callout light-gray mt-2 mb-2 md_mt-2 md_mb-2" style={{ maxWidth: '858px', margin: '0 auto' }}>
              <div>
                <p className="mb-0 md_mb-0 lg_md-0"><a href="https://www.arthritis.org/" target="_blank" rel="noreferrer" className="color-dark-red">Arthritis Foundation</a></p>
                <p className="font-weight-normal">Dedicated to the prevention, control, and cure of arthritis and other inflammatory rheumatic diseases, including PMR.</p>
              </div>
              <div className="horizontal-line-dark-red mt-2 mb-2 md_mt-2 md_mb-2" style={{ maxWidth: '273px' }}></div>
              <div>
                <p className="mb-0 md_mb-0 lg_md-0"><a href="https://www.vasculitisfoundation.org/" target="_blank" rel="noreferrer" className="color-dark-red">Vasculitis Foundation</a></p>
                <p className="font-weight-normal">Helps patients by providing information and promoting research efforts for the family of vasculitis diseases, including PMR.</p>
              </div>
              <div className="horizontal-line-dark-red mt-2 mb-2 md_mt-2 md_mb-2" style={{ maxWidth: '273px' }}></div>
              <div>
                <p className="mb-0 md_mb-0 lg_md-0"><a href="https://creakyjoints.org/about/about-ghlf/" target="_blank" rel="noreferrer" className="color-dark-red">Creaky Joints</a></p>
                <p className="font-weight-normal">Part of the Global Health Living Foundation, this is a network of thousands of arthritis patients and caregivers in all 50 states.</p>
              </div>
            </div>
            <p className="footnote">*These sites are not operated by Sanofi and Regeneron.</p>
          </div>

          <div className="text-center">
            <p className="headline-body">Learn more about PMR and how it impacts you</p>
            <p className="downloadable-resource">Downloadable resource about PMR</p>
            <p>This brochure takes a closer look at PMR</p>
            <div className="mb-4 md_mb-6" style={{ width: '140px', margin: '0 auto', position: 'relative' }}>
              <p><img src={ThumbPmrBrochure} alt="PMR and you brochure" width="140" style={{ border: '1px solid #979797' }} /></p>
              <p><a href={BrochurePdf} target="_blank" rel="noreferrer"><img src={IconDownload} alt="icon download" className="icon-arrow-download" style={{ position: 'absolute', bottom: '-20px', right: '-100px' }} /></a></p>
            </div>
          </div>

          <Link to="/" className="callout-link">
            <div className="callout next-page support-and-resources row" style={{ margin: '0 0 0 auto' }}>
              <div className="columns small-12 medium-8 large-9 align-self-middle mb-2 md_mb-0">Return to homepage</div>
              <div className="columns small-12 medium-4 large-3 align-self-middle"><img src={IconNext} alt="arrow icon" className="icon-next-arrow icon-next-arrow-animation" /></div>
            </div>
          </Link>
        </div>

        <div className="mt-4 md_mt-4 mb-2 md_mb-2"><img src={Gutter} alt="gutter" style={{ width: '80%', maxWidth: '796px' }}/></div>
        <br/>

      </div>
    </Layout>
  )
}
export default SupportAndResources
